export function footerMenuToggle() {
    $(document).on('click', '.js-footer-menu-trigger', function (event) {
        if ($(window).width() <= 992) {
            event.preventDefault();
            $(this).toggleClass('is-state-opened');
            $(this).closest('.js-footer-menu-item').siblings('.js-footer-menu-list').slideToggle();
        }
    })
}

export function footerMenuReset() {
    $('.js-footer-menu-trigger').removeClass('is-state-opened');
    $('.js-footer-menu-list').show();
}