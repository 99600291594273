export function headerSearchToggle() {
    $(document).on('click', '.js-header-search-trigger', function () {
        $(this).siblings('.js-header-search-holder').removeClass('is-state-hidden');
    });

    $('body').click(function (e) {
        if (!$('.js-header-search').is(e.target) && $('.js-header-search').has(e.target).length === 0) {
            $('.js-header-search-holder').addClass('is-state-hidden');
        }
    });
}