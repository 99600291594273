export const slideToggle = {
    emitClick: function (trigger, element) {
        element.style.maxHeight = trigger.classList.contains('is-state-collapsed') ? '' : element.scrollHeight + 'px';

        trigger.classList.toggle('is-state-collapsed');
    },
    emitList: function (headers) {
        headers.forEach(header => {
            header.addEventListener('click', ()=> {
                this.emitClick(header, header.nextElementSibling);
            });
        });
    },
    onLoad: function (headers) {
        headers.forEach(header => {
            if (header.classList.contains('is-state-collapsed')) {
                header.nextElementSibling.style.maxHeight = header.nextElementSibling.scrollHeight + 'px';
            }
        });
    }
};
