
import '../scss/styles.scss'

import 'babel-polyfill'

import './src/shared'
import './src/common'
import './src/footer-menu'
import './src/header-search'
import './src/cookies'
