import {footerMenuToggle, footerMenuReset} from "./footer-menu";
import {headerSearchToggle} from "./header-search";

$(window).on('load', function () {
    footerMenuToggle();
    headerSearchToggle();
});

$(window).on('resize', function () {
    if ($(window).width() > 992) {
        footerMenuReset();
    }
});
